import React from "react"
import Layout from '../components/layout'
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from 'gatsby'

const ProductPage = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          pages {
            product {
              title
              description
              url
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        {/* Meta */}
          <html lang="en" />
          <title>{data.site.siteMetadata.pages.product.title}</title>
          <meta charSet="utf-8" />
          <meta name="title" content={`Features - ${data.site.siteMetadata.title}`}/>
          <meta name="description" content={data.site.siteMetadata.pages.product.description} /> 
          <link rel="canonical" href={data.site.siteMetadata.pages.product.url} />
      </Helmet>
        <Layout>
            <h1>Product</h1>
        </Layout>
    </>
  )
}

export default ProductPage
